<template>
  <div class="img-zoom-box" :style="styles">
    <pic-zoom :url="getThumbnailImage(defaultImg)" :scale="2"></pic-zoom>
    <div class="swiper-img-box" v-show="imgZoomList.length>0">
      <button
        class="left-arrow"
        @click="nextBtn"
        type="button"
        :disabled="rightTalg"
        :class="rightTalg ? 'disabled-style': ''">
        <i class="iconfont">&#xe601;</i>
      </button>
      <div class="swiper-box-content">
        <div class="swiper-box-imglist" ref="imgList">
          <image-box
            v-for="(item, index) in imgZoomList"
            :src="getThumbnailImage(item.path)"
            @click.native="selectImg(item, index)"
            :key="index"
            class="img-item"
            :class="currentIndex === index ? 'img-active' : ''"
            alt=""/>
        </div>
      </div>
      <button
        class="right-arrow"
        type="button"
        :disabled="leftTalg"
        @click="prevBtn"
        :class="leftTalg ? 'disabled-style': ''">
        <i class="iconfont">&#xe601;</i>
      </button>
    </div>
    <!--商品停售遮罩层-->
    <div class="discontinuedMaskLayer" v-if="details.shelveStatus === 2">{{ $t('key1000070') }}</div>
  </div>
</template>

<script>
import PicZoom from '@/components/common/PicZoom';
import commonMixin from '@/components/mixin/common_mixin';
import imageBox from '@/components/common/imageBox';

export default {
  name: 'imgZoom',
  mixins: [commonMixin],
  props: {
    imgZoomList: {
      type: Array,
      default: []
    },
    details: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      currentIndex: 0,
      leftIndex: 0,
      imgListWidth: 0,
      defaultImg: '',
      leftTalg: false,
      rightTalg: false
    };
  },
  computed: {
    styles() {
      let style = '';
      if (this.details.shelveStatus === 2) {
        style = {
          position: 'relative'
        }
      }
      return style;
    }
  },
  created() {
    let v = this;
    if (v.imgZoomList.length > 0) {
      // 初始化略缩图
      v.defaultImg = v.imgZoomList.length > 0
        ? v.imgZoomList[0].path
        : '';
      if (v.imgZoomList.length < 5) {
        v.leftTalg = true;
        v.rightTalg = true;
      }
    }
    // 获取当前图片略缩图的宽度
    v.$nextTick(() => {
      v.imgListWidth = v.$refs.imgList.clientWidth;
    });
  },
  methods: {
    // 上一张
    prevBtn() {
      this.leftIndex++;
      let index = this.imgZoomList.length - 4;
      if (this.leftIndex < index) {
        this.rightTalg = false;
        this.leftTalg = false;
        this.$refs.imgList.style.left = `-${this.leftIndex * 70}px`;
      }
    },
    // 下一张
    nextBtn() {
      this.leftIndex--;
      let index = this.imgZoomList.length - 4;
      if (this.leftIndex < index) {
        this.rightTalg = false;
        this.leftTalg = false;
        this.$refs.imgList.style.left = `-${this.leftIndex * 70}px`;
      }
    },
    // 预览当前图片
    selectImg(item, index) {
      this.defaultImg = item.path;
      this.currentIndex = index;
    }
  },
  watch: {
    leftIndex: {
      handler(index) {
        let talgIndex = 5;
        let lengthIndex = this.imgZoomList.length;
        if (index === 0) {
          this.rightTalg = true;
        }
        if (index === lengthIndex - talgIndex) {
          this.leftTalg = true;
        }
      },
      deep: true,
      immediate: true
    }
  },
  components: {
    PicZoom,
    imageBox
  }
};
</script>

<style lang="less" scoped>
@import "src/assets/styles/shopping/common";

.img-zoom-box {
  width: 380px;
  height: 380px;

  /deep/ .magnifier-box .mouse-cover {
    background-attachment: scroll;
    background-repeat: repeat;
    cursor: move;
    z-index: 1;
    background-position: 0 0;
    background-color: transparent;
    background-image: url("../../../../assets/images/scanning.png");
  }

  .swiper-img-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 20px;
    box-sizing: border-box;

    .swiper-box-content {
      width: 340px;
      height: 60px;
      position: relative;
      overflow: hidden;

      .swiper-box-imglist {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        transition: all 0.4s ease;

        .img-item {
          width: 60px;
          height: 60px;
          display: inline-block;
          cursor: pointer;
          margin-right: 10px;

          &:hover {
            border: 1px solid @subject_color;
            box-sizing: border-box;
          }
        }

        .img-item:last-child {
          margin-right: 0;
        }

        .img-active {
          border: 1px solid @subject_color;
        }
      }
    }

    .left-arrow {
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 100;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      border-radius: 2px;

      .iconfont {
        font-size: 20px;
        color: #DDDDDD;
        transform: rotate(180deg);
      }

      &:hover {
        background-color: @subject_color;

        .iconfont {
          color: #fff;
        }
      }
    }

    .right-arrow {
      position: absolute;
      top: 0;
      right: 0;
      width: 18px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 100;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      border-radius: 2px;

      .iconfont {
        font-size: 20px;
        color: #DDDDDD;
      }

      &:hover {
        background-color: @subject_color;

        .iconfont {
          color: #fff;
        }
      }
    }

    .disabled-style {
      cursor: not-allowed;
      background-color: initial !important;

      .iconfont {
        color: #DDDDDD;
      }
    }
  }

  .discontinuedMaskLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    color: #fff;
    background-color: rgb(119, 119, 119);
    opacity: 0.7;
  }
}
</style>
